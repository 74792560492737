import React from 'react';
import styled from 'styled-components';

import MaxWidthWrapper from '@components/MaxWidthWrapper';
import Footer from '@components/Footer';
import Spacer from '@components/Spacer';
import SEO from '@components/SEO';
import HorizontalRule from '@components/HorizontalRule';
import List from '@components/List';
import Asterisk from '@components/Asterisk';

export default function ConfirmedPage() {
  return (
    <Wrapper>
      <MaxWidthWrapper>
        <Content>
          <Title>Email Course Preview</Title>
          <p>
            Across the internet, an abundance of opt-in forms ask for
            your email address. Most of the time, these forms offer
            little clarity around what exactly you're signing up for.
          </p>
          <p>
            I want to do things a bit differently. In this document,
            I'll explain how this course preview works, and what sorts
            of emails you can expect to receive. You can use this
            information to decide whether or not you want to sign up.
          </p>
          <HorizontalRule />
          <p>
            When you submit the opt-in form, you'll receive an email
            asking you to confirm by clicking a link. This is known as
            a "double opt-in" process. It confirms that you have
            access to the email inbox you entered.
            <Asterisk content="This might seem silly, as though someone could otherwise go around maliciously signing up their enemies to receive free email courses. But it's an industry-standard practice, and senders are penalized for not following it. 🤷" />
          </p>
          <p>
            Once you've confirmed, you'll immediately receive the
            first email in the sequence.
          </p>
          <p>
            I'm actively iterating on this sequence, to make it as
            beneficial and worthwhile as possible, but you can expect
            to receive 4 to 6 emails over the course of a week. The
            emails might include:
          </p>
          <List>
            <List.ListItem>
              Written lessons that explain thorny aspects of the CSS
              language (eg. how “stacking contexts” work).
            </List.ListItem>
            <List.ListItem>
              Links to some of the blog posts I've written, that
              explain CSS concepts in depth.
            </List.ListItem>
            <List.ListItem>
              More information about the course (frequently asked
              questions, testimonials, etc).
            </List.ListItem>
            <List.ListItem>
              Access to portions of the course for free.
            </List.ListItem>
            <List.ListItem>
              A discount code for the full course (possibly).
            </List.ListItem>
          </List>

          <p>
            <strong>
              This sequence won't just be a bunch of marketing fluff.
            </strong>{' '}
            I want you to get value out of this email sequence, even
            if you never wind up buying the full course.
          </p>

          <h2>Updates newsletter</h2>

          <p>
            In addition to receiving the email sequence, you'll also
            be subscribed to receive the “CSS for JavaScript
            Developers updates newsletter”.
          </p>
          <p>
            This newsletter is sent every month or two, and it shares
            behind-the-scenes updates about the course. Even though
            the course is fully released/completed, I plan on keeping
            it up to date as the CSS language evolves. I send
            occasional email to this list to let folks know about new
            content in the course, or new features on the course
            platform.
          </p>
          <p>
            I might also send occasional promotional content about{' '}
            <em>future</em> courses. It takes me about a year to
            produce a course, and so I certainly won't have course
            launches often, but I may send several emails during a
            launch.
          </p>

          <h2>Unsubscribing</h2>
          <p>
            If you change your mind and no longer wish to receive
            email from me, you can of course unsubscribe. Unsubscribes
            should take place immediately, and certainly within 24
            hours you won't receive any more automated marketing
            emails from me.
          </p>
          <p>
            <strong>I make this really easy.</strong> One-click
            unsubscribe links are included at the bottom of every
            email you receive. You can also reach out at
            support@joshwcomeau.com, and I can manually remove you.
          </p>

          <Spacer $size={64} />
        </Content>
      </MaxWidthWrapper>
      <Footer />
      <SEO />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: min(250px, 10vh);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Content = styled.main`
  --color-primary: var(--color-tertiary);

  h2 {
    font-size: 1.45rem;
    margin-bottom: 1em;
    margin-top: 2em;
  }

  strong {
    color: var(--color-tertiary);
  }
`;

const Title = styled.h1`
  font-size: 2em;
  font-weight: 500;
  margin-bottom: 3rem;
`;
